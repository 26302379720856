<template>
  <div id="messages-detail" v-if="activeMessage != null" style="overflow: auto; max-height: calc(100vh - 70px)">
    <div class="top-bar">
      <div class="left">
        <!-- <i class="icon-reply-all"></i>
        <i class="icon-reply"></i> -->
      </div>

      <div class="middle">
        <i style="width: 22px; height: 22px; background: #b3c0ce; transform: rotate(-180deg); cursor: pointer" class="icon-arrow" v-if="activeMessageIndex != 0" @click="changeActiveMessage(activeMessageIndex - 1)"></i>

        <div>{{ activeMessageIndex + 1 }} - {{ messages.Items.length }}</div>
        <i style="width: 22px; height: 22px; background: #b3c0ce; cursor: pointer" class="icon-arrow" v-if="activeMessageIndex + 1 < messages.Items.length" @click="changeActiveMessage(activeMessageIndex + 1)"></i>
      </div>

      <div class="right">
        <i v-if="currentUser.ID != activeMessage.Sender.Id && filter.MessageBoxType == 1" class="icon-flag" style="cursor: pointer" :style="activeMessage.HasFlag ? 'background:red;' : ''" @click="toggleFlag"></i>
        <!-- <i style="background: #8697a8" class="icon-print"></i> -->
        <i class="icon-delete" @click="deleteMessage" v-if="currentUser.ID != activeMessage.Sender.Id && filter.MessageBoxType == 1"></i>
      </div>
    </div>

    <div class="message-content" style="padding-bottom: 0px">
      <div class="message-content-top">
        <div class="message-header">
          <div class="message-header-left">
            <div class="circle">{{ activeMessage.Sender.Title.substr(0, 1).toUpperCase() }}</div>
            <div class="title">{{ activeMessage.Sender.Title }}</div>
          </div>
          <div class="message-header-right" style="text-align: center">
            <div>{{ $moment(activeMessage.CreatedDateTime).format("Do MMMM YYYY HH:mm") }}</div>
          </div>
        </div>
      </div>
      <div class="content" style="overflow: auto; height: calc(100vh - 610px)">
        <div v-html="activeMessage.MessageText"></div>
      </div>
      <div class="message-content-bottom">
        <div class="file">
          <div class="doc1" v-for="(file, index) in activeMessage.Attachments" :key="index">
            <div class="file">
              <img :src="getExtension(file.Extension)" />
            </div>
            <div class="text">{{ file.FilenameReal }}</div>
            <i class="icon-download" @click="downloadFiles(file.ObjectKey)"></i>
          </div>
        </div>
      </div>
      <div class="textarea">
        <el-input type="textarea" :rows="3" :placeholder="$localization('web-detail-write-answer')" v-model="sendMessage.MessageText2" v-if="filter.MessageBoxType == 1"> </el-input>
      </div>
      <div class="buttons" v-if="filter.MessageBoxType == 1">
        <div class="left">
          <el-upload :action="$client.defaults.baseURL + 'Account/UploadFile?&type=5'" :headers="{
            Authorization: $client.defaults.headers.common['Authorization'],
          }" :show-file-list="false" :on-success="fileSuccess">
            <el-button size="small" type="primary" class="download"><i style="background: #44566c" class="icon-attachment"></i></el-button>
          </el-upload>
        </div>
        <div class="right">
          <el-button class="btn-send" @click="replyMessage"><i style="background: #fff" class="icon-reply"></i>
            <div>{{ $localization('web-detail-send') }}</div>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      attachments: [],
    };
  },
  methods: {
    fileSuccess(res) {
      this.activeMessage.Attachments.push(res.Item);
    },
    async deleteMessage() {
      this.$confirm("Bu mesajı tamamen silmek istediğiniz emin misiniz? Bu işlem geri alınamaz.", "Uyarı", {
        confirmButtonText: "Evet, Sil",
        cancelButtonText: "Vazgeç",
        type: "warning",
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
      })
        .then(async () => {
          var res = await this.$store.dispatch("deleteMessageMB", { MessageId: this.activeMessage.ID });
          if (!res.HasError) {
            this.activeMessage = null;
            await this.$store.dispatch("getMessagesMB", this.filter);
          }
        })
        .catch(() => { });
    },
    async replyMessage() {
      this.sendMessage.RepliedMessageId = this.activeMessage.ID;
      this.sendMessage.Title = this.activeMessage.Title;
      this.sendMessage.MessageText = this.sendMessage.MessageText2;

      this.sendMessage.ReceiverId = this.activeMessage.Sender.Id;
      this.sendMessage.Attachments = this.activeMessage.Attachments;
      var res = await this.$store.dispatch("sendMessageMB", this.sendMessage);
      if (!res.HasError) {
        this.$store.dispatch("getMessagesMB", this.filter);
        this.sendMessage.MessageText = null;
        this.sendMessage.MessageText2 = null;
        this.$message.success("Cevabınız başarıyla gönderilmiştir.");
      }
    },
    async toggleFlag() {
      await this.$store.dispatch("toggleHasFlagMB", { MessageId: this.activeMessage.ID });
      this.activeMessage.HasFlag = !this.activeMessage.HasFlag;
    },
    changeActiveMessage(index) {
      var targetMessage = this.messages.Items[index];
      this.setActiveMessage(targetMessage);
    },
    setActiveMessage(item) {
      this.$store.commit("setActiveMessageMB", item);
    },
  },
  watch: {
    // 'filter.getActiveMessageMB': function() {
    //   this.$store.dispatch("getMessagesMB", this.filter);
    // },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    activeMessageIndex() {
      return this.messages.Items.findIndex((x) => x.ID == this.activeMessage.ID);
    },
    activeMessage() {
      return this.$store.getters.getActiveMessageMB;
    },
    messages() {
      return this.$store.getters.getMessagesMB;
    },
    sendMessage() {
      return this.$store.getters.getSendMessageMB;
    },
    filter() {
      return this.$store.getters.getFilterMB;
    },
  },
};
</script>

<style lang="less">
#messages-detail {
  width: 100%;
  height: 100vh;
  background-color: #fff;

  .top-bar {
    height: 60px;
    border-top: none;
    border-bottom: 1px solid #eaedf0;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 5px;

    .left {
      display: flex;
      align-items: center;
      gap: 25px;
    }

    .middle {
      display: flex;
      align-items: center;
      gap: 15px;

      div {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #8697a8;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 25px;
    }
  }

  .message-content {
    padding: 35px;

    .message-content-top {
      .message-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .message-header-left {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 20px;

          .circle {
            background: #ff8a48;
            color: #fff;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .title {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #44566c;
          }
        }
      }

      .message-header-right {
        div {
          width: 84px;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: #8697a8;
        }
      }
    }

    .content {
      height: auto;
      font-weight: 400;
      font-size: 15px;
      line-height: 32px;
      color: #44566c;
      margin-top: 50px;
    }

    .message-content-bottom {
      display: flex;
      margin-top: 30px;

      div {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #44566c;
      }

      .file {
        display: flex;
        gap: 30px;

        .doc1,
        .doc2 {
          display: flex;
          align-items: center;
          height: 72px;
          gap: 25px;
          background: #f8fafb;
          padding: 10px;
        }
      }
    }

    .textarea {
      width: 100%;
      margin-top: 30px;

      .el-textarea__inner {
        background: #f8fafb;
        border: none;
        padding: 25px;
      }

      ::placeholder {
        font-weight: 400;
        font-size: 15px;
        color: #8697a8;
        line-height: 18px;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .left {
        display: flex;
        gap: 10px;
      }

      .right {
        display: flex;
        align-items: center;
        padding: 0;

        .btn-send {
          display: flex;
          align-items: center;
          width: 112px;
          height: 40px;
          background: #234A98;
          border-radius: 5px;

          div {
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
          }

          i {
            float: left;
            margin-right: 6px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

.download {
  padding: 0 !important;
  background: unset !important;
  border: none;
  width: 22px;
  height: 22px;
}</style>
